<template>
  <div class="project">
    <div class="project_header">
      <p>支付金额</p>
      <p>支付方式</p>
      <p>支付时间</p>
    </div>
    <div class="project_main">
      <el-collapse v-model="activeName" v-if="list">
        <el-collapse-item v-for="v in list" :name="v.id">
          <template slot="title">
            <div class="item-title">
              <p>{{ v.biz_name }}</p><p>{{ v.offline }}</p>
            </div>
          </template>
          <div class="main">
            <div class="content" v-for="item in v.data">
              <p>{{item.amount}}</p>
              <p>{{item.method}}</p>
              <p>{{item.paytime}}</p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <p class="tip" v-if="!list">{{msg}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "project",
  data() {
    return {
      activeName: [] ,
      list:'',
      msg:'加载中...'
    };
  },
  created() {
    let id = this.$route.query.id
    if(id){
      let ts = Math.round(new Date().getTime() / 1000).toString();
      let url = "screen/api/get_project";
      let params = {
        linkageid: id,
        timestamp: ts
      };
      this.$axios.post(url, params).then((res) => {
        this.list = res.data.data
        console.log(this.list)
        this.list.forEach(v=>{
          let s = 0;
          v.data.forEach(b=>{
            s+=(b.amount-0)
          })
          console.log(s)
        })
        if(!this.list){
          this.msg = '暂无数据'
        }
      });
    }
  }
}
</script>

<style scoped>
.project{
  height: 80%;
  width: 92%;
  margin: 40px auto 0;
  position: relative;
  z-index: 100000;
}
.project_main{
  height: 98%;
  width: 100%;
  overflow-y: auto;
}
.project_main::-webkit-scrollbar{
  display: none;
}
.project_main .tip{
  color: #909399;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.item-title{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}
.project_header{
  width: 70%;
  height: 20px;
  margin: 0 auto 20px;
  font-size: 17px;
  color: #FFFFFF;
}
.project_header p{
  margin: 0;
}
.project_header p:nth-child(1){
  width: 40%;
  float: left;
}
.project_header p:nth-child(2){
  width: 45%;
  float: left;
}
.project_header p:nth-child(3){
  width: 150px;
  float: right;
}
.main{
  width: 70%;
  padding: 35px 0 53px;
  margin: 0 auto;
}
.main .content{
  width: 100%;
  height: 23px;
  color: black;
  margin-top: 35px;
}
.main .content:nth-child(1){
  margin-top: 0;
}
.main .content p{
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #FFFFFF;
  float: left;
}
.main .content p:nth-child(1){
  width: 40%;
}
.main .content p:nth-child(2){
  width: 45%;
}
.main .content p:nth-child(3){
  width: 150px;
  float: right;
}
.el-collapse{
  border: 0!important;
  clear: both;
}
.el-collapse-item:nth-child(1){
  margin-top: 0;
}
.el-collapse-item{
  margin-top: 10px;
}
.item-title{
  font-size: 16px;
  color: #FFFFFF;
}
/deep/.el-collapse-item__header{
  background-color: rgba(47, 127, 175, 0.41) !important;
  border: 0!important;
  border-radius: 5px;
  transition: all 0.3s;
}
/deep/.el-collapse-item__header.is-active{
  border-radius: 5px 5px 0 0 ;
}
/deep/.el-collapse-item__wrap{
  background-color: rgba(47, 127, 175, 0.19) !important;
  border: 0!important;
  border-radius: 0 0 5px 5px ;
}
/deep/.el-collapse-item__wrap .el-collapse-item__content{
  padding: 0;
}
/deep/.el-icon-arrow-right{
  color: #FFFFFF;
}
</style>
